<template>
  <div v-if="src" class="main">
    <el-alert type="warning" :closable="false">
      <div slot="title">
        若经纬度不合法，请点击此处进行刷新：
        <el-link
          type="primary"
          :underline="false"
          icon="el-icon-refresh"
          :disabled="disabled"
          @click="init(false)"
        >
          刷新
        </el-link>
      </div>
    </el-alert>
    <iframe
      class="iframe"
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="no"
      :src="src"
      :style="{'pointer-events': disabled ? 'none' : 'unset'}"
    />
  </div>
</template>
<script>
export default {
  name: 'CustomMap',
  props: {
    policy: {
      type: Number,
      default: 0
    },
    lat: {
      type: Number,
      default: 0
    },
    lng: {
      type: Number,
      default: 0
    },
    mapKey: {
      type: String,
      default: '6VIBZ-E6M6Z-MPVX7-TSSQZ-XDO3S-KTB2R'
    },
    referer: {
      type: String,
      default: '乐享养老优服务'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      src: ''
    }
  },
  created() {
    window.addEventListener('message', event => {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data
      if (loc && loc.module === 'locationPicker') { // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        if (!this.disabled) {
          this.$emit('callback', loc)
        }
      }
    }, false)
  },
  methods: {
    init(latLng = true) {
      // 文档 https://lbs.qq.com/webApi/component/componentGuide/componentPicker
      const url = new URL('https://apis.map.qq.com/tools/locpicker')
      url.searchParams.set('type', 1)
      url.searchParams.set('policy', this.policy)
      url.searchParams.set('key', this.mapKey)
      if (latLng && this.lat && this.lng) {
        url.searchParams.set('coord', `${this.lat},${this.lng}`)
      }
      if (this.referer) {
        url.searchParams.set('referer', this.referer)
      }
      this.src = url.toString()
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  aspect-ratio: 1.3/1;
  max-height: calc(100vh - 250px);

  .iframe {
    border: 1px solid rgba(136, 136, 136, 0.5);
    border-radius: 5px;
    margin-top: 5px;
  }
}
</style>
